import GreyList from './gray-list'
import WhiteList from './white-list'
import homeRoute from './home'
import systemRoute from './system'

// 注： meta.type 值代表某种类型的用户支持的路由
//  0 所有角色都支持 1 个人 2 公司

const routerList = [...homeRoute, ...systemRoute]

export default { GreyList, WhiteList, routerList }
