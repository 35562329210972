import dayjs from 'dayjs'
export default {
  dateFormat: (date, format = 'YYYY-MM-DD HH:mm:ss') => {
    if (!date) return '-'
    return dayjs(date).format(format)
  },
  hidePhone: (phone) => {
    if (!phone) {
      return ''
    } else {
      return phone.substr(0, 3) + '****' + phone.substr(7)
    }
  },
  authStatus: (value) => {
    if (value === 0) {
      return '未认证'
    } else {
      return '已认证'
    }
  },
  orderStatus: (value) => {
    return ['待支付', '已付款', '已取消', '超时关闭', '已退单', '退款中'][value] || '-'
  },
  payType: (value) => {
    return ['未知', '微信支付', '支付宝支付', '对公转帐', '商品兑换码'][value] || '-'
  },
  orderType: (value) => {
    return ['线上下单', '手动录入'][value - 1] || '-'
  },
  money: (value, showUnit = true) => {
    return (showUnit ? '¥' : '') + (value / 100).toThousand(2)
  },
  sizeFormat: value => {
    if (value > 1024 * 1024) {
      return `${(value / (1024 * 1024)).toFixed(1)}M`
    } else {
      return `${(value / (1024)).toFixed(1)}KB`
    }
  },
  serviceStatus (status) {
    return [0, 1, 3].includes(status) ? '服务中' : status === 2 ? '待确收' : '已完成'
  },
  serviceOrderStatus (status) {
    // :0-待支付、1-已付款(正常)、2-已取消、3-超时关闭、4-已退款
    return ['待支付', '已付款', '已取消', '超时关闭', '已退款'][status]
  },
  serviceType (type) {
    // 1-合同代写、2-合同审核、3-合同修改
    return ['-', '合同代写', '合同审核', '合同修改'][type] || '-'
  },
  writCategoryName (id, list) {
    const getName = function (id) {
      const row = list.find(row => row.id === id)
      if (row) {
        const name = getName(row.parentId)
        return (name ? name + '/' : '') + row.title
      } else {
        return ''
      }
    }
    return getName(id) || '-'
  },
  userStatus: (value) => {
    const strArray = ['已启用', '已停用', '已注销']
    return strArray[value]
  },
  orgaAuthStatus: (value) => {
    const strArray = ['未认证', '已认证']
    return strArray[value]
  },
  orgaStatus: (value) => {
    const strArray = ['已启用', '已停用', '已注销']
    return strArray[value]
  }
}
