<template>
  <transition name="el-loading-fade" @after-leave="handleAfterLeave">
    <div
      v-show="visible"
      :class="[customClass, { 'is-fullscreen': fullscreen }]"
      :style="{ backgroundColor: background || '' }"
      class="ft-loading-mask">
      <div class="ft-loading-spinner">
        <svg v-if="!spinner" class="default-svg" preserveAspectRatio="xMidYMid" viewBox="0 0 100 100"
             xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <g transform="rotate(0 50 50)">
            <rect fill="#3350cb" height="12" rx="3" ry="6" width="6" x="47" y="24">
              <animate attributeName="opacity" begin="-0.9166666666666666s" dur="1s" keyTimes="0;1" repeatCount="indefinite"
                       values="1;0"></animate>
            </rect>
          </g>
          <g transform="rotate(30 50 50)">
            <rect fill="#3350cb" height="12" rx="3" ry="6" width="6" x="47" y="24">
              <animate attributeName="opacity" begin="-0.8333333333333334s" dur="1s" keyTimes="0;1" repeatCount="indefinite"
                       values="1;0"></animate>
            </rect>
          </g>
          <g transform="rotate(60 50 50)">
            <rect fill="#3350cb" height="12" rx="3" ry="6" width="6" x="47" y="24">
              <animate attributeName="opacity" begin="-0.75s" dur="1s" keyTimes="0;1" repeatCount="indefinite"
                       values="1;0"></animate>
            </rect>
          </g>
          <g transform="rotate(90 50 50)">
            <rect fill="#3350cb" height="12" rx="3" ry="6" width="6" x="47" y="24">
              <animate attributeName="opacity" begin="-0.6666666666666666s" dur="1s" keyTimes="0;1" repeatCount="indefinite"
                       values="1;0"></animate>
            </rect>
          </g>
          <g transform="rotate(120 50 50)">
            <rect fill="#3350cb" height="12" rx="3" ry="6" width="6" x="47" y="24">
              <animate attributeName="opacity" begin="-0.5833333333333334s" dur="1s" keyTimes="0;1" repeatCount="indefinite"
                       values="1;0"></animate>
            </rect>
          </g>
          <g transform="rotate(150 50 50)">
            <rect fill="#3350cb" height="12" rx="3" ry="6" width="6" x="47" y="24">
              <animate attributeName="opacity" begin="-0.5s" dur="1s" keyTimes="0;1" repeatCount="indefinite"
                       values="1;0"></animate>
            </rect>
          </g>
          <g transform="rotate(180 50 50)">
            <rect fill="#3350cb" height="12" rx="3" ry="6" width="6" x="47" y="24">
              <animate attributeName="opacity" begin="-0.4166666666666667s" dur="1s" keyTimes="0;1" repeatCount="indefinite"
                       values="1;0"></animate>
            </rect>
          </g>
          <g transform="rotate(210 50 50)">
            <rect fill="#3350cb" height="12" rx="3" ry="6" width="6" x="47" y="24">
              <animate attributeName="opacity" begin="-0.3333333333333333s" dur="1s" keyTimes="0;1" repeatCount="indefinite"
                       values="1;0"></animate>
            </rect>
          </g>
          <g transform="rotate(240 50 50)">
            <rect fill="#3350cb" height="12" rx="3" ry="6" width="6" x="47" y="24">
              <animate attributeName="opacity" begin="-0.25s" dur="1s" keyTimes="0;1" repeatCount="indefinite"
                       values="1;0"></animate>
            </rect>
          </g>
          <g transform="rotate(270 50 50)">
            <rect fill="#3350cb" height="12" rx="3" ry="6" width="6" x="47" y="24">
              <animate attributeName="opacity" begin="-0.16666666666666666s" dur="1s" keyTimes="0;1" repeatCount="indefinite"
                       values="1;0"></animate>
            </rect>
          </g>
          <g transform="rotate(300 50 50)">
            <rect fill="#3350cb" height="12" rx="3" ry="6" width="6" x="47" y="24">
              <animate attributeName="opacity" begin="-0.08333333333333333s" dur="1s" keyTimes="0;1" repeatCount="indefinite"
                       values="1;0"></animate>
            </rect>
          </g>
          <g transform="rotate(330 50 50)">
            <rect fill="#3350cb" height="12" rx="3" ry="6" width="6" x="47" y="24">
              <animate attributeName="opacity" begin="0s" dur="1s" keyTimes="0;1" repeatCount="indefinite"
                       values="1;0"></animate>
            </rect>
          </g>
        </svg>
        <i v-else :class="spinner"></i>
        <p v-if="text" class="el-loading-text">{{ text }}</p>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data () {
    return {
      text: null,
      spinner: null,
      background: null,
      fullscreen: true,
      visible: false,
      customClass: ''
    }
  },

  methods: {
    handleAfterLeave () {
      this.$emit('after-leave')
    },
    setText (text) {
      this.text = text
    }
  }
}
</script>
<style lang="less">
.ft-loading-parent--relative {
  position: relative !important;
}
.ft-loading-parent--hidden {
  overflow: hidden !important;
}
.ft-loading-mask {
  position: absolute;
  z-index: 2000;
  background-color: rgba(135, 135, 135, .3);
  margin: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  transition: opacity .3s;
}
.ft-loading-spinner {
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  text-align: center;
  position: absolute;
}
.default-svg {
  width: 200px;
}

</style>
