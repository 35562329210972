// 首页
const overview = () => import('@/webapp/home/overview.vue')
const customer = () => import('@/webapp/home/customer.vue')
const service = () => import('@/webapp/home/service.vue')
const Api = require('../apis/path')
export default [
  {
    path: '/home',
    redirect: '/home/overview',
    meta: {
      id: '3ad099c6-f34a-4f9f-a8ce-013ee1a65d8e',
      icon: '',
      name: 'home',
      title: '首页',
      apis: []
    },
    children: [
      {
        path: '/overview',
        meta: {
          id: 'c73cb419-a294-4380-8c61-529f0a91c1f6',
          icon: 'icon-zonglanicon',
          name: 'overview',
          title: '总览',
          iconColor: '#507BFD',
          apis: []
        },
        component: overview
      },
      {
        path: '/customer',
        meta: {
          id: '709c72a5-1423-4416-bc87-35f4a347982a',
          icon: 'iconfont icon-qiyeyonghuguanliicon',
          iconColor: 'rgb(0, 205, 224)',
          name: 'customer',
          title: '客户列表',
          apis: [
            `get:${Api.path.user}/orga/{id}/channel/users/list`,
            `get:${Api.path.user}/orga/{id}/channel/orgas/list`,
            `get:${Api.path.user}/orga/{orgaId}/member`,
            `post:${Api.path.user}/orga/{orgaId}/channel/orgas`,
            `put:${Api.path.user}/orga/{orgaId}/channel/orgas/{id}`,
            `get:${Api.path.user}/orga/{orgaId}/channel/orga/{id}`,
            `get:${Api.path.user}/orga/{id}/member`,
            `get:${Api.path.user}/orga/{id}/channel/orga/{orgaId}/roles`,
            `put:${Api.path.user}/orga/{id}/admin`
          ]
        },
        component: customer
      },
      {
        path: '/service',
        meta: {
          id: 'a34018d2-617a-4e40-b1b7-4fcb408e8935',
          icon: 'iconfont icon-fuwuguanliicon1',
          iconColor: '#ff7172',
          name: 'service',
          title: '购买服务列表',
          apis: [
            `get:${Api.path.user}/orga/{orgaId}/channel/user/services`,
            `get:${Api.path.user}/orga/{orgaId}/channel/orga/services`
          ]
        },
        component: service
      }
    ]
  }
]
