const accountInfo = () => import('@/webapp/system/account-info.vue')
const accountSecure = () => import('@/webapp/system/account-secure.vue')
const orgaAccount = () => import('@/webapp/system/account-orga.vue')
const memberRole = () => import('@/webapp/system/member-role.vue')
const memberList = () => import('@/webapp/system/member-list.vue')
const Api = require('../apis/path')
export default [
  {
    path: '/system',
    redirect: '/account/info',
    meta: {
      id: '7f207da8-7552-4d6e-ad1c-cbbe6ef5d8e2',
      icon: '',
      name: 'system',
      title: '系统管理',
      type: [0],
      apis: []
    },
    children: [
      {
        path: '/account',
        meta: {
          id: 'b750bed9-a5fb-486e-b238-cc8aba1de2ed',
          icon: 'iconfont icon-zhanghaoshezhiicon',
          iconColor: '#527aee',
          name: 'account',
          title: '帐号设置',
          type: [0],
          apis: []
        },
        children: [
          {
            path: '/info',
            meta: {
              id: '',
              icon: '',
              name: 'info',
              title: '个人信息',
              type: [0],
              apis: []
            },
            component: accountInfo
          },
          {
            path: '/secure',
            meta: {
              id: '',
              icon: '',
              name: 'secure',
              title: '帐号安全',
              type: [0],
              apis: []
            },
            component: accountSecure
          },
          {
            path: '/orga-account',
            meta: {
              id: '3038b992-376f-4062-84e5-c78e60bf9137',
              icon: '',
              name: 'orga-account',
              title: '渠道商帐号',
              type: [5],
              apis: [
                `get:${Api.path.user}/orga/{orgaId}`,
                `put:${Api.path.user}/orga/{orgaId}`,
                `post:${Api.path.user}/orga/{orgaId}/auth`,
                `post:${Api.path.user}/orga/{orgaId}/unregister`
              ]
            },
            component: orgaAccount
          }
        ]
      },
      {
        path: '/member',
        meta: {
          id: 'f58cd254-7c66-4e9e-a326-9de646858cfd',
          icon: 'iconfont icon-yuangongzhongxinicon',
          iconColor: '#45acf6',
          name: 'member',
          title: '员工中心',
          apis: [
          ]
        },
        children: [
          {
            path: '/role',
            meta: {
              id: 'a6a3d803-6071-4231-ad95-24a429b71cbc',
              icon: '',
              name: 'role',
              title: '角色管理',
              apis: [
                `get:${Api.path.user}/operation`,
                `get:${Api.path.user}/orga/{orgaId}/role`,
                `put:${Api.path.user}/orga/{orgaId}/role/{roleId}`,
                `post:${Api.path.user}/orga/{orgaId}/role`,
                `get:${Api.path.user}/orga/{orgaId}/role/{roleId}`,
                `delete:${Api.path.user}/orga/{orgaId}/role/{roleId}`
              ]
            },
            component: memberRole
          },
          {
            path: '/list',
            meta: {
              id: '62fc0a8a-2871-4f3a-a9d3-162ec0101590',
              icon: '',
              name: 'member',
              title: '成员管理',
              apis: [
                `get:${Api.path.user}/orga/{orgaId}/department`,
                `get:${Api.path.user}/orga/{orgaId}/role`,
                `post:${Api.path.user}/orga/{orgaId}/department`,
                `put:${Api.path.user}/orga/{orgaId}/department/{depId}`,
                `delete:${Api.path.user}/orga/{orgaId}/department/{depId}`,
                `get:${Api.path.user}/orga/{orgaId}/member`,
                `put:${Api.path.user}/orga/{orgaId}/member/{memberId}`,
                `post:${Api.path.user}/orga/{orgaId}/member`,
                `get:${Api.path.user}/orga/{orgaId}/member/{memberId}`,
                `delete:${Api.path.user}/orga/{orgaId}/member/{memberId}`,
                `put:${Api.path.user}/orga/{orgaId}/member/{memberId}/status`
              ]
            },
            component: memberList
          }
        ]
      }
    ]
  }

]
