import Vue from 'vue'
import ElementUI from 'element-ui'
import ftLoading from '@/components/ft-loading'
import axios from 'axios'
import App from './App.vue'
import router from './router'
import filters from '@/utils/filter'
import '@/styles/index.less'
import '@/assets/css/theme/index.css'
import {
  resubmitInterceptor,
  permission,
  errorInterceptor
} from '@ft/common-library'
import AuthUtils from '@/utils/usreinfo'
import '@/assets/css/common.css'
import('@/assets/less/common.less')
;(async () => {
  Vue.config.productionTip = false

  Vue.use(ElementUI, { size: 'small' })
  Vue.use(permission)
  Vue.use(ftLoading)

  const domainString = location.hostname.split('.')
  const domain = [
    domainString[domainString.length - 2],
    domainString[domainString.length - 1]
  ].join('.')
  const auth = new AuthUtils(domain)
  auth.userInfo = null
  Vue.prototype._auth = auth
  window._auth = auth

  // 请求时添加登录信息
  axios.interceptors.request.use(
    function (config) {
      config.headers.token = auth.getToken()
      return config
    },
    function (error) {
      return Promise.reject(error)
    }
  )

  const ieIe = !!window.ActiveXObject || 'ActiveXObject' in window
  const IE11RouterFix = {
    methods: {
      hashChangeHandler () {
        this.$router.push(
          window.location.hash.substring(1, window.location.hash.length)
        )
      }
    },
    mounted () {
      window.addEventListener('hashchange', this.hashChangeHandler)
    },
    destroyed () {
      window.removeEventListener('hashchange', this.hashChangeHandler)
    }
  }

  // ie10,ie11
  const mixins = ieIe ? [IE11RouterFix] : []

  // 处理 area 数据
  const removeNullChildren = (list) => {
    list.forEach((row) => {
      if (!row.children || row.children.length === 0) {
        delete row.children
      } else {
        removeNullChildren(row.children)
      }
    })
  }
  removeNullChildren(window.dict.area)
  removeNullChildren(window.dict.standardIndustry)

  if (auth.isLogin() && !auth.userInfo) {
    const res = await axios.get('/operation/v1/user/current')
    if (res.data.result) {
      auth.userInfo = res.result
    }
  }

  Object.keys(filters).forEach((row) => {
    Vue.filter(row, filters[row])
  })

  window.global = new Vue({
    mixins: mixins,
    router,
    render: (h) => h(App)
  }).$mount('#app')

  resubmitInterceptor(axios)
  errorInterceptor(axios, {
    vm: window.global,
    needProcess: (error) => {
      // if (error?.response?.data?.code === 400004) {
      //   window.location.href = `//sso.ftling.com?url=${encodeURIComponent(
      //     window.location.href
      //   )}`
      //   return true
      // }
      // if (error?.response?.data?.code === 404001) {
      //   window.global.$message.error(
      //     '无法调用接口，请添加接口权限: ' +
      //       error?.config?.method +
      //       ':' +
      //       error?.config?.url
      //   )
      //   return true
      // }
      // if (
      //   error?.response?.data?.status === 400 ||
      //   error?.response?.data?.status === 404
      // ) {
      //   window.global.$message.error(error?.response?.data?.message)
      //   return true
      // }
      if (error?.response?.data?.status === 500) {
        window.global.$message.error('服务器错误，请联系管理员')
        return true
      }
      return false
    },
    loginUrl: () => {
      return `//sso.ftling.com?url=${encodeURIComponent(
      window.location.href
    )}`
    }
  })

  auth.onUserIdChange = function (userId) {
    if (!userId) {
      auth.clearToken()
      auth.userInfo = null
      window.sessionStorage.clear()
      window.location.href = `//sso.ftling.com?url=${encodeURIComponent(
        window.location.href
      )}`
    } else {
      auth.userInfo = null
      // 更新权限
      axios.get('/api/update_current_permission')
      window.location.href = '/'
    }
  }
  axios.interceptors.response.use((res) => {
    if (res?.data?.code > 200 && res?.data?.message) {
      // throw new Error('错误：' + res?.data?.message)
      window.global.$message.error('错误：' + res?.data?.message)
      return Promise.reject(new Error('错误：' + res?.data?.message))
    } else {
      return Promise.resolve(res)
    }
  })
  // 更新权限
  axios.get('/api/update_current_permission')
})()
