export default {
  path: 'greyList',
  name: 'greyList',
  meta: {
    id: '',
    name: 'greyList',
    title: '灰名单Api列表',
    apis: [
      'get:/operation/v1/user/current',
      'post:/operation/v1/user/current',
      'post:/operation/v1/user/current/password',
      'post:/operation/v1/user/current/phone',
      'get:/operation/v1/gateway/user/{userId}/operation',
      // 'post:/operation/v1/common/file',
      'get:/operation/v1/gateway/user/{userId}/orga/{orgaId}/operation',
      // 'get:/operation/v1/orga/{orgaId}/member',
      // 'post:/operation/v1/user/current/auth',
      // 'get:/operation/v1/orga/{orgaId}/role',
      'put:/operation/v1/common/code/{biz}',
      'post:/operation/v1/common/code/{biz}',
      // 'post:/operation/v1/user/current/unregister',
      'post:/operation/v1/common/code/cos',
      'get:/operation/v1/common/code/cos',
      'post:/operation/v1/common/validate/user/account',
      'get:/operation/v1/orga/{id}/channel/stat',
      'get:/operation/v1/orga/{id}/channel/stat/detail',
      'get:/operation/v1/orga/{id}'
    ]
  }
}
